/*
 * @Author: Alan
 * @Date: 2023-05-09 18:01:56
 * @LastEditors: Alan
 * @LastEditTime: 2023-06-02 19:32:39
 * @Description: Redux store 的配置文件。该文件定义了 Redux store 并将多个 Reducer 合并到一个根 reducer 中。
 * @FilePath: \F1-M1-WEB-Code\store\store.js
 */

import { configureStore } from "@reduxjs/toolkit";
import mainSiteConfigSlice from "./mainSiteConfigSlice";
import balanceSlice from "./balanceSlice";
import campaignSlice from "./campaignSlice";
import bonusSlice from "./bonusSlice";
import promotionSlice from "./promotionSlice";
import userCenterSlice from "./userCenterSlice";
import spinSlice from "./spinSlice";
import gameSlice from "./gameSlice";
const store = configureStore({
    reducer: {
        mainSiteConfig: mainSiteConfigSlice.reducer,
        balance: balanceSlice.reducer,
        campaign: campaignSlice.reducer,
        bonus: bonusSlice.reducer,
        promotion: promotionSlice.reducer,
        userCenter: userCenterSlice.reducer,
        spin: spinSlice.reducer,
        game: gameSlice.reducer,
    },
});

export default store;
